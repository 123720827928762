import React from "react"
import Layout from "components/layout"
import SEO from "components/seo"
import AboutPage from "pages/about"

export const Head = () => (
  <SEO
    title="品牌故事"
    description="衣庫國際成立於2015年，是台灣最大的現貨成衣進口批發商。由擁有服裝時尚業30年經驗的主管領軍，帶領一群服飾業精英，穩健發展。我們相信堅持創造出品質，專注淬煉出專業。"
    pathname="/about"
  />
)

export default () => (
  <Layout>
    <AboutPage />
  </Layout>
)
